
import * as actionTypes from './actionTypes';

export const setData = (actionType, data) => {
    return {
        type: actionType,
        value: data
    }
}

export const getData = () => {
    return dispatch => {

        /* firebase.auth().signInAnonymously()
        .then(
            firebase.database().ref('INFO/').once('value').then(response => {
                dispatch(setData(actionTypes.GET_INFO, response.val()))
            })
        .then(
            firebase.database().ref('PROFILES/').once('value').then(response => {
                dispatch(setData(actionTypes.GET_PROFILES, response.val()))
            }))
        .then(
            firebase.database().ref('MEDIA/').once('value').then(response => {
                dispatch(setData(actionTypes.GET_MEDIA, response.val()))
            }))
        .then(
            firebase.database().ref('EVENTS/').once('value').then(response => {
                dispatch(setData(actionTypes.GET_EVENTS, response.val()))
            }))
        .catch (error => {

        })) */;
    }
}