import React from 'react';

import Link from '../Link';
import Wrapper from '../HOC/Wrapper';

const header = (props) => (

    <Wrapper>
        {/* <div class="notice-top-bar bg-primary" data-sticky-start-at="100">
            <button class="hamburguer-btn hamburguer-btn-light notice-top-bar-close m-0 active" data-set-active="false">
                <span class="close">
                    <span></span>
                    <span></span>
                </span>
            </button>
        </div> */}
        <header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': false, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
        <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
            <div className="header-container container">
            <div className="header-row">
                <div className="header-column w-100">
                <div className="header-row justify-content-between">
                    <div className="header-logo z-index-2 col-lg-2 px-0">
                    <Link href="/">
                        <img alt="Techmartsupp" width="30%" height="75%" data-sticky-width={30} data-sticky-height={20} data-sticky-top={84} src="img/logo.png" />
                    </Link>
                    </div>
                    <div className="header-nav header-nav-links justify-content-end pe-lg-4 me-lg-3">
                    <div className="header-nav-main header-nav-main-effect-3 header-nav-main-sub-effect-1">
                        <nav className="collapse">
                        <ul className="nav nav-pills" id="mainNav">
                            <li><Link href="/" className="nav-link" data-bs-toggle="collapse" data-bs-target=".collapse">Home</Link></li>
                            <li><Link href="/about" className="nav-link" data-bs-toggle="collapse" data-bs-target=".collapse">About</Link></li>
                            <li><Link href="/services" className="nav-link" data-bs-toggle="collapse" data-bs-target=".collapse">Services</Link></li>
                            <li><Link href="/contact" className="nav-link" data-bs-toggle="collapse" data-bs-target=".collapse">Contact</Link></li>
                        </ul>
                        </nav>
                    </div>
                    </div>
                    <ul className="header-extra-info custom-left-border-1 d-none d-xl-block">
                    <li className="d-none d-sm-inline-flex ms-0">
                        <div className="header-extra-info-icon">
                        <i className="icons icon-phone text-3 text-color-dark position-relative top-3" />
                        </div>
                        <div className="header-extra-info-text line-height-2">
                        <span className="text-1 font-weight-semibold text-color-default">CALL US NOW</span>
                        <strong className="text-4"><Link href="tel:+234 805 3271 176" className="text-color-hover-primary text-decoration-none">+234 805 3271 176 </Link></strong>
                        </div>
                    </li>
                    </ul>
                    <div className="d-flex col-auto pe-0 ps-0 ps-xl-3">
                    <div className="header-nav-features ps-0 ms-1">
                        <div className="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex top-2 ms-2">
                        {/* <Link href="showroom.html" className="">
                            <img src="img/icons/icon-cart-big.svg" height={30} alt="" className="header-nav-top-icon-img" />
                            <span className="cart-info">
                            <span className="cart-qty">*</span>
                            </span>
                        </Link> */}
                        </div>
                    </div>
                    </div>
                    <button className="btn header-btn-collapse-nav ms-4 navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".collapse">
                    <i className="fas fa-bars" />
                    </button>
                </div>
                </div>
            </div>
            </div>
        </div>
        </header>
    </Wrapper>

);

export default header;