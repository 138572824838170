import React from 'react';

import MainWrapper from '../../HOC/MainWrapper';
import Header from '../../Header';
import Footer from '../../Footer';

const Layout = ( props ) => (
    <MainWrapper>
       <Header/>
            {props.children}
        <Footer/>
    </MainWrapper>
);

export default Layout;