import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Config from './services/Config';
import AsyncComponent from './services/AsyncComponent';

import history from './utilities/History';

import Layout from './components/HOC/Layout';

import * as actions from './store/actions';

const Home = AsyncComponent(() => import('./scenes/Home').then(module => module.default))
const About = AsyncComponent(() => import('./scenes/About').then(module => module.default))
const Services = AsyncComponent(() => import('./scenes/Services').then(module => module.default))
const Contact = AsyncComponent(() => import('./scenes/Contact').then(module => module.default))


const PAGES = {
  '/': Home,
  '/about': About,
  '/services': Services,
  '/contact': Contact
};

class App extends Component {

  constructor(props) {
    super(props);

    //setup Firebase for data operations
    //new Config().setupFirebaseApp();
    
    this.state = {
        pathname: this.props.pathname,
    };
}

  //used to get the current scene(page) from the pathname
  getScene = (pathname) => {
    if(pathname === null || pathname === "") return

    var r = pathname.split('/').filter(Boolean);

    switch(r.length){
      case 1: return `/${r[0]}`;
      case 2: return `/${r[0]}/${r[1]}`;
      case 5: return `/${r[0]}/${r[1]}/${r[2]}`;
      default: return `/`;
    }
  }

  //used to get the single item query details for a scene from the pathname
  getSceneObject = (pathname) => {
    if(pathname === null || pathname === "") return

    var r = pathname.split('/').filter(Boolean);

    return {id: r[3], object: r[4]}

  }

  componentDidMount() {

    //used to initialize Redux Store
    this.props.init();

    
    //For async Page Load History Management
    window.onpopstate = (e) => {
      this.setState({pathname: window.location.pathname})
    };
    history.onChange((pathname) => {
        this.setState({pathname});
    });

   
    
  }


  render() {
    const Scene = PAGES[this.getScene(this.state.pathname)]

    const sceneObjects = this.getSceneObject(this.state.pathname);

    return (
      <Layout>
        <Scene {...sceneObjects}/>
        
      </Layout>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
      init: () => dispatch(actions.getData())
  }
}
export default connect(null, mapDispatchToProps)(App);

App.propTypes = {
  pathname: PropTypes.oneOf(Object.keys(PAGES)).isRequired,
};
