import * as actionTypes from '../actions/actionTypes';

const initialState = {
    socialAccounts: [],
    mission: [],
    mandate: [],
    socialMedia: [],
    socialAccountsInfo:[],
    donate: [],
    newsletterSubscribe: [],
    vision: [],
    allData: [],
    homeData: [],
    aboutData: [],
    mediaData:[],
    contactData: [],
    info: [],
    media: [],
    events: [],
    profiles: [],
};


const filterInfoData = (state = initialState, rawData) => {

    let socialAccounts_ = rawData.filter((data) => {
        return data.section === "socialAccounts";
    });
    
    let mission_ = rawData.filter((data) => {
        return data.section === "mission";
    });

    let mandate_ = rawData.filter((data) => {
        return data.section === "mandate";
    });

    let socialMedia_ = rawData.filter((data) => {
        return data.section === "socialMedia";
    });

    let socialAccountsInfo_ = rawData.filter((data) => {
        return data.section === "socialAccountsInfo";
    });

    let donate_ = rawData.filter((data) => {
        return data.section === "donate";
    });

    let newsletterSubscribe_ = rawData.filter((data) => {
        return data.section === "newsletterSubscribe";
    });

    let vision_ = rawData.filter((data) => {
        return data.section === "vision";
    });

    let allData_ = rawData.filter((data) => {
        return data.page === "all";
    });

    let homeData_ = rawData.filter((data) => {
        return data.page === "home";
    });

    let aboutData_ = rawData.filter((data) => {
        return data.page === "about";
    });

    let mediaData_ = rawData.filter((data) => {
        return data.page === "media";
    });

    let contactData_ = rawData.filter((data) => {
        return data.page === "contact";
    });

    return {
        ...state, 
        info: rawData,
        socialAccounts: socialAccounts_,
        mission: mission_,
        mandate: mandate_,
        socialMedia: socialMedia_,
        socialAccountsInfo: socialAccountsInfo_,
        donate: donate_,
        newsletterSubscribe: newsletterSubscribe_,
        vision: vision_,
        allData: allData_,
        homeData: homeData_,
        aboutData: aboutData_,
        mediaData: mediaData_,
        contactData: contactData_
    }
  }


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INFO:
            return filterInfoData(state, action.value);
        case actionTypes.GET_PROFILES:
            return {...state, profiles: action.value};
        case actionTypes.GET_MEDIA:
            return {...state, media: action.value};
        case actionTypes.GET_EVENTS:
            return {...state, events: action.value};
        default: 
            return state
    }

};

export default reducer;