import React from 'react';

import history from '../../utilities/History';

const Link = (props) => {
    
    const onClick = (e) => {
        const aNewTab = e.metaKey || e.ctrlKey;
        const anExternalLink = props.href.startsWith('http');
        const anInternalLink = props.href.startsWith('/');

        if (anInternalLink && !aNewTab && !anExternalLink) {
            e.preventDefault();
            history.push(props.href);
        }
    };

    return (
        <a {...props} onClick={onClick}>
            {props.children}
        </a>
    );
};

export default Link;