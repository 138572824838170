import React from 'react';

import Link from '../Link';

const Footer = (props) => (

    <footer id="footer" className="border-0 mt-0">
    <div className="container py-5">
        <div className="row py-3">
        <div className="col-md-4 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
            <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <img width={45} src="img/demos/auto-services/icons/icon-location.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light position-relative bottom-3'}" />
            </div>
            <div className="feature-box-info line-height-1 ps-2">
                <span className="d-block font-weight-bold text-color-light text-5 mb-2">Address</span>
                <p className="text-color-light text-4 line-height-4 font-weight-light mb-0">3B, Olaide Benson Str Onigbongbo, Maryland, Lagos, NGR.</p>
            </div>
            </div>
        </div>
        <div className="col-md-4 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
            <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <i className="icons icon-phone text-9 text-color-light position-relative top-4" />
            </div>
            <div className="feature-box-info line-height-1 ps-2">
                <span className="d-block font-weight-bold text-color-light text-5 pb-1 mb-1">Call Us Now</span>
                <Link href="tel:+234 805 3271 176" className="text-color-light text-4 line-height-7 text-decoration-none">+234 805 3271 176 </Link>
            </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
            <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <img width={45} src="img/demos/auto-services/icons/car-winch.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light position-relative bottom-3'}" />
            </div>
            <div className="feature-box-info line-height-1 ps-xl-3">
                <span className="d-block font-weight-bold text-color-light text-5 pb-1 mb-1">24/7 Assistance</span>
                <Link href="tel:+234 805 3271 176" className="text-color-light text-4 line-height-7 text-decoration-none">+234 805 3271 176 </Link>
            </div>
            </div>
        </div>
        </div>
    </div>
    <hr className="bg-light opacity-2 my-0" />
    <div className="container pb-5">
        <div className="row text-center text-md-start py-4 my-5">
        <div className="col-md-6 col-lg-4 align-self-center text-center text-md-start text-lg-center mb-5 mb-lg-0">
            <img src="img/logo.png" width="40%" className="img-fluid" alt="" />
            <p className="text-color-light font-weight-medium text-3-5">Specialist in Electrical Power Protection Controls and Systems, offering voltage protection for homes and office appliances, industrial machines in order to keep them safe and running.</p>
        </div>
        <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">Contact Us</h5>
            <ul className="list list-unstyled">
            <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5">ADDRESS</span> 
                <p className="text-color-light font-weight-medium text-3-5">3B, Olaide Benson Str Onigbongbo, Maryland, Lagos, NGR.</p>
            </li>
            <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5 mb-1">PHONE</span>
                <ul className="list list-unstyled font-weight-light text-3-5 mb-0">
                <li className="text-color-light line-height-3 mb-0">
                    Sales/Services: <Link href="tel:+2348053271176" className="text-decoration-none text-color-light text-color-hover-default">+234 805 3271 176 </Link>
                </li>
                </ul>
            </li>
            <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5">EMAIL</span>
                <Link href="mailto:info@techmartsupp.com" className="text-decoration-none font-weight-light text-3-5 text-color-light text-color-hover-default">info@techmartsupp.com</Link><br/>
                <Link href="mailto:sales@techmartsupp.com" className="text-decoration-none font-weight-light text-3-5 text-color-light text-color-hover-default">sales@techmartsupp.com</Link>
            </li>
            </ul>
            <ul className="social-icons social-icons-medium">
            <li className="social-icons-instagram">
                <Link href="http://www.instagram.com/techmartsupp" className="no-footer-css" target="_blank" title="Instagram"><i className="fab fa-instagram" /></Link>
            </li>
            <li className="social-icons-twitter mx-2">
                <Link href="http://www.twitter.com/TMS_ng" className="no-footer-css" target="_blank" title="Twitter"><i className="fab fa-twitter" /></Link>
            </li>
            <li className="social-icons-facebook">
                <Link href="http://www.facebook.com/techmartsuppng" className="no-footer-css" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></Link>
            </li>
            </ul>
        </div>
        <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">Our Solutions</h5>
            <ul className="list list-unstyled mb-0">
            <li className="mb-0"><Link href="/services">Technical Sales and Procurement</Link></li>
            <li className="mb-0"><Link href="/services">Telemetry &amp; Metering Services</Link></li>
            <li className="mb-0"><Link href="/services">Telematics (Vehicle Tracking and Fuel Level Monitoring)</Link></li>
            <li className="mb-0"><Link href="/services">Electrical Protection &amp; Control</Link></li>
            <li className="mb-0"><Link href="/services">Energy Audit</Link></li>
            <li className="mb-0"><Link href="/services">General Electricals &amp; Consultancy Services</Link></li>
            <li className="mb-0"><Link href="https://onkron.ng/" target="_blank">Sales of TV Supports </Link></li>
            </ul>
        </div>
        </div>
    </div>
    <div className="footer-copyright bg-light py-4">
        <div className="container py-2">
        <div className="row">
            <div className="col">
            <p className="text-center text-3 mb-0">Techmartsupp Solutions Limited © {new Date().getFullYear().toString()}. All Rights Reserved.</p>
            </div>
        </div>
        </div>
    </div>
    </footer>

)

export default Footer;